import http from '../http'
import { timeAgo } from '@/utils/dates'

export const systemTypes = [
  {
    code: 'heroku',
    label: 'Heroku',
  },
  {
    code: 'sfdc',
    label: 'Salesforce',
  },
]

export const getMiniStepperStatus = () => {
  return http.$get(`/datasystem/ministepper/status/`).then((response) => {
    return {
      allRequirementsDone:
        response.user_visited_layout &&
        response.user_visited_detect_and_merge &&
        response.crawler_finished,
      layoutsVisited: response.user_visited_layout,
      directorsVisited: response.user_visited_detect_and_merge,
      crawlerFinished: response.crawler_finished,
    }
  })
}

export const setMiniStepperStatus = (data) => {
  const payload = {}

  if (data.layoutsVisited) {
    payload.user_visited_layout = true
  } else if (data.directorsVisited) {
    payload.user_visited_detect_and_merge = true
  }

  return http.$post(`/datasystem/ministepper/status/`, payload)
}

const mapConnectionResponse = (connection) => {
  let auth = {}

  if (connection.system_type === 'sfdc') {
    auth = {
      url: connection.auth_params.url,
      username: connection.auth_params.user_name,
    }
  } else if (connection.system_type === 'heroku') {
    auth = {
      database: connection.auth_params.database,
      host: connection.auth_params.host,
      port: connection.auth_params.port,
      schema: connection.auth_params.schema,
      username: connection.auth_params.user,
    }
  }

  return {
    id: connection.id,
    name: connection.name,
    systemType: connection.system_type,
    systemTypeLabel: systemTypes.find(
      (type) => type.code === connection.system_type
    )?.label,
    description: connection.description,
    modifiedDate: timeAgo(connection.modified),
    status: connection.status,
    statusColor:
      connection.status === 'disconnected' ? 'ap-red--text' : 'ap-green--text',
    authStatus: connection.auth_status,
    ...auth,
  }
}

export const getConnections = () => {
  return http.$get('/datasystem/serviceauth').then((response) => {
    return response.data.map((connection) => {
      return mapConnectionResponse(connection)
    })
  })
}

export const getConnectionById = (id) => {
  return http.$get('/datasystem/serviceauth/' + id).then((connection) => {
    return mapConnectionResponse(connection)
  })
}

export const disconnect = (id) => {
  return http
    .$post(`/datasystem/serviceauth/${id}/disconnect/`)
    .then((connection) => {
      return mapConnectionResponse(connection)
    })
}

export const connect = (id) => {
  return http
    .$post(`/datasystem/serviceauth/${id}/connect/`)
    .then((connection) => {
      return mapConnectionResponse(connection)
    })
}

const mapConnnectionPayload = (connection) => {
  let authParams = {}

  if (connection.systemType === 'heroku') {
    authParams = {
      user: connection.username,
      password: connection.password,
      host: connection.host,
      port: connection.port,
      database: connection.database,
      schema: connection.schema,
    }
  } else {
    authParams = {
      url: connection.url,
      user_name: connection.username,
      password: connection.password,
      security_token: connection.securityToken,
    }
  }

  return {
    name: connection.name,
    description: connection.description,
    system_type: connection.systemType,
    auth_params: authParams,
    auto_connect: true,
  }
}

export const updateConnection = (connection) => {
  return http
    .$post(
      '/datasystem/serviceauth/' + connection.id,
      mapConnnectionPayload(connection)
    )
    .then((connection) => {
      return mapConnectionResponse(connection)
    })
}

export const createConnection = (connection) => {
  return http
    .$post('/datasystem/serviceauth/', mapConnnectionPayload(connection))
    .then((connection) => {
      return mapConnectionResponse(connection)
    })
}

export const deleteConnection = (id) => {
  http.$delete('/datasystem/serviceauth/' + id)
}
