import http from '../http'
import { getRules, getRule, RULE_TYPES } from '../business-rules/rules'
import { mapResponseMultiMapRule } from '../business-rules/rules'

export const getMultimaps = async () => {
  return await getRules({
    type: RULE_TYPES.multiMap,
  })
}

export const getMultimapsById = async (id) => {
  return await getRule(id)
}

export const updateMultimap = (rule) => {
  const objectFields = []

  rule.groups.forEach((group) => {
    objectFields.push({
      fields: rule.objects.map((object) => group[object.name]),
      name: null,
    })
  })

  return http
    .$post(`/rule/rules/${rule.id}`, {
      name: rule.name,
      description: rule.description,
      status: 'active',
      type: 'MultiMap',
      entities: 'account',
      rule: {
        crawler_id: rule.crawlerId,
        objects: rule.objects.map((object) => object.name),
        object_fields: objectFields,
      },
    })
    .then((response) => mapResponseMultiMapRule(response.data[0]))
}

export const createMultimap = (rule) => {
  const objectFields = []

  rule.groups.forEach((group) => {
    objectFields.push({
      fields: rule.objects.map((object) => group[object.name]),
      name: null,
    })
  })

  return http
    .$post(`/rule/rules/`, {
      name: rule.name,
      description: rule.description,
      status: 'active',
      type: 'MultiMap',
      entities: 'account',
      rule: {
        crawler_id: rule.crawlerId,
        objects: rule.objects.map((object) => object.name),
        object_fields: objectFields,
      },
    })
    .then((response) => mapResponseMultiMapRule(response))
}

export const deleteMultimap = (id) => {
  http.$delete('/rule/rules/' + id)
}
